// If you don't want to use TypeScript you can delete this file!
import * as React from "react"
import { useState } from "react"
import { PageProps, Link, graphql } from "gatsby"
import useAuth from "../../hooks/useAuth";
import ReactMarkdown from "react-markdown";

interface TripReportProps {
    title: string;
    presentOnTrip: Array<string>;
    cave: Object;
    date: Date;
    description: string;
    route: string;
    timeTaken: string;
    author: string;
}

const TripReport: React.FC<TripReportProps> = ({title, presentOnTrip, cave, date, route, timeTaken, description, author}) => {
    const { isAuthenticated } = useAuth()
    return (
    <>
        <h1>{title} - {date.toLocaleDateString('en-GB')}</h1>
        <h3 style={{marginBottom:'0px'}}>{cave.name} - {cave.nickname}</h3>
        <div style={{marginTop:'0px'}}>{cave.location ? `${cave.location}, ${cave.region}`: cave.region}</div>
        <h3 style={{marginBottom:'0px'}}>Present on trip</h3>
        {!!isAuthenticated ? <div style={{marginTop:'0px'}}>{presentOnTrip}</div> : <div style={{marginTop:'0px'}}>Login to see people on this trip</div>}
        <h3 style={{marginBottom:'0px'}}>Route Information:</h3>
        <div style={{marginTop:'0px'}}>{route}</div>
        <div>Time taken: {timeTaken}</div>
        <h3>Description</h3>
        <div dangerouslySetInnerHTML={{__html: description}}>
            
        </div>
        <small>Written by: {!!isAuthenticated ? author : "Login to see author"}</small>
    </>
  )
}

export default TripReport
