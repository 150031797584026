import * as React from "react"
import { graphql } from "gatsby"
import TripReport from "../../../../components/tripReport/tripReport"
import Layout from "../../../../components/layout"
import ReactMarkdown from "react-markdown"
import NavBar from "../../../../components/navBar/navBar"
import Seo from "../../../../components/seo"

function TripReportComponent(props) {
  const trip = props.data.strapiTripReport
  if (!trip) return null 
  return (
    <Layout>
        <Seo title={trip.title} />
        <NavBar />
        <TripReport 
              title={trip.title}
              presentOnTrip={trip.presentOnTrip}
              cave={trip.cave}
              date={new Date(trip.date)}
              description={trip.description}
              route={trip.route}
              timeTaken={trip.timeTaken}
              author={trip.author} />
    </Layout>
  
  )
}

export default TripReportComponent

export const query = graphql`
  query($id: String!) {
    strapiTripReport(id: { eq: $id }) {
        id
        cave {
            name
            nickname
            region
            location
        }
        title
        date
        route
        timeTaken
        presentOnTrip   
        description
        author
    }
}`
